<template>
  <v-app id="inspire">
    <v-content>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>App 로그인</v-toolbar-title>
               
                
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>           
                  <v-text-field
                    name="login"
                    label="아이디"
                    
                    prepend-icon="mdi-email"
                    type="text"
                    autocomplete="new-password22"
                    required
                    v-model="forms.email"
                    :rules="options.emailRules"
                    ></v-text-field>
                  <v-text-field                     
                      autocomplete="new-password2"
                      
                      name="password"
                      label="비밀번호"
                      prepend-icon="mdi-lock"
                      v-model="forms.userpw"
                      :rules="options.passwordRules"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="!valid" @click="onLogin"  
                    :loading="isLoading">로그인</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
//import Vue from 'vue'
//import axios from 'axios'
//import moment from 'moment-timezone'
//import Util from "@/util"

export default {
  components: {
    //VWidget
  },
  data () {
    return {
      url : process.env.VUE_APP_APP_URL, 
      title : process.env.VUE_APP_APP_NAME,
      show1: false,
      isLoading: false,
      valid: false, 
      
     
      forms: {
        email: '',
        userpw: '',
      },
      api: {
        login:'admin/auth/login'
      },
      options: {
        passwordRules: [
          v => !!v || '비밀번호를 입력하세요',
          v => (v && v.length >= 6) || '비밀번호는 6자 이상입니다'
        ],
        emailRules: [
          v => !!v || '아이디를 입력하세요'          
        ],
      },      
      query: {
        firebase: this.$store.getters.firebase,
        ref: this.$store.getters.ref,        
        user: this.$store.getters.user,        
      },

    };
  },
  created () {
    //this.$log.warn('프로덕션모드시 표시안됨')
    //console.log('콘솔로그 프로덕션모드시 안보임')

  },
  mounted () {
   

    //
  },
  methods: {
    
    onLogin () {
      //this.$log.error(this.query.ref.loginHistory)
      if (this.$refs.form.validate()) {
       // this.$log.warn('onloginok')
        this.onSignIn()
      }
    },
    
    onSignIn () {
      const _this = this
      this.$log.warn('로그인시작')
      this.isLoading=true
      //this.forms.created = this.moment().format()
      this.query.firebase.auth().signInWithEmailAndPassword(this.forms.email, this.forms.userpw)
        .then(firebaseUser => {
          this.$toasted.show('로그인 성공', {type: 'success'})          
          //this.$log.warn(firebaseUser.user)
          
          this.$store.dispatch('login',firebaseUser.user)
          this.isLoading=false
          this.forms.message = '로그인성공' 
          this.forms.isSuccess = true
         
          //this.$router.push('/')
          this.$router.push({name:"app.home"})
          
        })
        .catch(() => {
          _this.isLoading=false
          _this.$toasted.show('아이디나 비밀번호가 정확한지 확인하세요', {type: 'error'})
          
        })
    }
  }
};
</script>
